:root {
  /* Hintergrund Button */
  --buttonBackgroundColor: unset;
  /* Textfarbe Button */
  --buttonColor: unset;
  /* Textfarbe Button */
  --buttonHeight: unset;
  /* Hintergrund der Offer Button */
  --offerButtonBackgroundColor: unset;
  /* Textfarbe der Offer Button */
  --offerButtonColor: unset;
  /* Hintergrund der Offer Header Beschreibung */
  --offerHeaderBackground: unset;
  /* Textfarbe der Offer Header Beschreibung */
  --offerHeaderColor: unset;
  /* Hintergrund der HighlightOffer Header Beschreibung */
  --offerHighlightHeaderBackground: unset;
  /* Textfarbe der HighlightOffer Header Beschreibung */
  --offerHighlightHeaderColor: unset;
  /* Textausrichtung der Überschrift für Highlights */
  --offerHighlightHeaderTextAlign: unset;
  /* Textfarbe der HighlightOffer Header Beschreibung */
  --offerFooterBackground: unset;
  /* Textfarbe der Offer Footer Beschreibung */
  --offerFooterColor: unset;
  /* Angabe der Spalten in % */
  --offerFxFlex: 33%;
  /* Angabe der Spalten in % -> screen and (max-width: 599px) */
  --offerFxFlexXS: 100%;
  /* Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px) */
  --offerFxFlexSM: 50%;
  /* Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px) */
  --offerFxFlexMD: 50%;
  /* Angabe der Spalten in % -> screen and (min-width: 1280px) and (max-width: 1919px) */
  --offerFxFlexLG: 33%;
  /* Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px) */
  --offerFxFlexXL: 33%;
  /* Angabe der Spalten in % */
  --landingFxFlex: 33%;
  /* Angabe der Spalten in % -> screen and (max-width: 599px) */
  --landingFxFlexXS: 100%;
  /* Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px) */
  --landingFxFlexSM: 50%;
  /* Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px) */
  --landingFxFlexMD: 50%;
  /* Angabe der Spalten in % ->  screen and (min-width: 1280px) and (max-width: 1919px) */
  --landingFxFlexLG: 33%;
  /* Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px) */
  --landingFxFlexXL: 33%;
  /* Navigation Hintergrundfarbe */
  --naviBackgroundColor: unset;
  /* Navigation Text */
  --naviColor: unset;
  /* Navigation Hover Hintergrundfarbe */
  --naviHoverBackgroundColor: unset;
  /* Navigation Hover Text */
  --naviHoverColor: unset;
  /* Navigation Active Hintergrundfarbe */
  --naviActiveBackgroundColor: unset;
  /* Navigation Active Text */
  --naviActiveColor: unset;
  /* Navigation Border Top */
  --naviBorderTop: unset;
  /* Navigation Border Right */
  --naviBorderRight: unset;
  /* Navigation Border Bottom */
  --naviBorderBottom: unset;
  /* Navigation Border Left */
  --naviBorderLeft: unset;
  /* Navigation Border Top */
  --naviHoverBorderTop: unset;
  /* Navigation Border Right */
  --naviHoverBorderRight: unset;
  /* Navigation Border Bottom */
  --naviHoverBorderBottom: unset;
  /* Navigation Border Left */
  --naviHoverBorderLeft: unset;
  /* Navigation Border Top */
  --naviActiveBorderTop: unset;
  /* Navigation Border Right */
  --naviActiveBorderRight: unset;
  /* Navigation Border Bottom */
  --naviActiveBorderBottom: unset;
  /* Navigation Active Border Left */
  --naviActiveBorderLeft: unset;
  /* Navigation Textdecoration */
  --naviTextDecoration: unset;
  /* Navigation Hover Textdecoration */
  --naviHoverTextdecoration: unset;
  /* Navigation Active Textdecoration */
  --naviActiveTextdecoration: unset;
  /* FontFamily */
  --hupFontFamily: unset;
  /* Hintergrund CheckedCheckbox */
  --checkBoxBackgroundColor: unset;
  /* Farbe des Rahmens */
  --checkBoxBorderColor: unset;
  /* Farbe des Häckchens */
  --checkBoxCheckmarkColor: unset;
  /* Textfarbe Secondary Button */
  --buttonSecondaryColor: unset;
  /* Hintergrund Secondary Button */
  --buttonSecondaryBackgroundColor: unset;
}
