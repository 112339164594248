/* You can add global styles to this file, and also import other style files */
@import "assets/css/bootstrap.min.scss";

@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/material-icons/iconfont/material-icons.css";
@import "assets/font-awesome/css/brands.min.css";
@import "assets/font-awesome/css/fontawesome.min.css";
@import "assets/font-awesome/css/light.min.css";
@import "assets/font-awesome/css/regular.min.css";
@import "assets/font-awesome/css/solid.min.css";
@import "assets/font-awesome/css/thin.min.css";


html, body { height: 100%; }

body {
  margin: 0;
  word-wrap: break-word;
}

#hupContent *, #loginBox * {
  font-family: var(--hupFontFamily);
}

#hupContent *.material-icons {
  font-family: "Material Icons";
}

#hupContent i.fas, #hupContent i.far {
  font-family: 'Font Awesome 6 Pro' !important;
}
#hupContent img:not(.image) {
  min-height: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox.text-wrap label.mat-checkbox-layout {
  white-space: normal;
}

.mat-checkbox-vert {
  .mdc-form-field {
    .mdc-checkbox {
      position: inherit;
    }
  }
  .mdc-form-field>label{
    align-self: flex-start;
    margin-top: 10px;
  }
}

mat-form-field {
  width: 100%;
}


#hupContent .nav-link {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color: var(--naviColor)!important;
  background-color: var(--naviBackgroundColor)!important;
  padding: 15px 15px 15px 15px!important;
  border-top: var(--naviBorderTop)!important;
  border-right: var(--naviBorderRight)!important;
  border-bottom: var(--naviBorderBottom)!important;
  border-left: var(--naviBorderLeft)!important;
  text-decoration: var(--naviTextDecoration)!important;
}

#hupContent .nav-link.active {
  background-color: var(--naviActiveBackgroundColor)!important;
  color: var(--naviActiveColor)!important;
  border-top: var(--naviActiveBorderTop)!important;
  border-right: var(--naviActiveBorderRight)!important;
  border-bottom: var(--naviActiveBorderBottom)!important;
  border-left: var(--naviActiveBorderLeft)!important;
  text-decoration: var(--naviActiveTextdecoration)!important;
}

#hupContent .nav-link:hover {
  background-color: var(--naviHoverBackgroundColor)!important;
  color: var(--naviHoverColor)!important;
  border-top: var(--naviHoverBorderTop)!important;
  border-right: var(--naviHoverBorderRight)!important;
  border-bottom: var(--naviHoverBorderBottom)!important;
  border-left: var(--naviHoverBorderLeft)!important;
  text-decoration: var(--naviHoverTextdecoration)!important;
}

#hupContent img:not(.image) {
  max-width: unset;
}

#hupContent button {
  //background-color: var(--buttonBackgroundColor);
  //color: var(--buttonColor);
  //height: var(--buttonHeight);
}

.lds-ring {

}

.mat-mdc-card-content {
  padding: 16px;
}

.mat-mdc-card-title {
  padding-bottom: 16px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{
  background-color: var(--buttonBackgroundColor)!important;
  color: var(--buttonColor)!important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background,
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple
{
  border-color: var(--checkBoxBorderColor)!important;
  background-color: var(--checkBoxBackgroundColor)!important;
  --mdc-checkbox-selected-checkmark-color: var(--checkBoxCheckmarkColor)!important;
}

.mdc-checkbox {
  box-sizing: unset!important;
  align-self: flex-start
}

.mdc-checkbox label {
  padding-top: 15px;
}

.mat-mdc-checkbox.mat-accent {

}

.mat-mdc-form-field-icon-suffix button{
  background-color: unset!important;
  color: unset!important;
}

.iconInInput {
  padding-right: 35px!important;
  padding-bottom: 35px!important;
}

.mat-mdc-raised-button.mat-primary {
  background-color: var(--buttonBackgroundColor)!important;
  color: var(--buttonColor)!important;
}

#hupContent .btn-secondary{
  color: var(--buttonSecondaryColor)!important;
  background-color: var(--buttonSecondaryBackgroundColor)!important;
  border-color: var(--buttonSecondaryBackgroundColor)!important;
}
